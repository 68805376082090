import Container from 'components/container'
import Layout from 'components/layout'
import Card from 'components/molecules/card'
import Seo from 'components/molecules/seo'
import { algoliaClient } from 'components/organisms/algolia'
import {
  ArticleGridItem,
  ArticlesGrid,
  LoadMore,
} from 'components/organisms/articlesList'
import CommunitySearchBar from 'components/organisms/communitySearchBar'
import { graphql, PageProps } from 'gatsby'
import { getCurrentLang } from 'helpers/locale'
import { useResourceTextData } from 'hooks/useResourceTextData'
import React from 'react'
import { InfiniteHitsProvided } from 'react-instantsearch-core'
import {
  Configure,
  connectInfiniteHits,
  connectStateResults,
  InstantSearch,
} from 'react-instantsearch-dom'
import styled from 'styled-components'

const HITS_PER_PAGE = 16

export const SearchResultsMsg = styled.div`
  font-size: 1.1rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 2rem;
`

const Hits = ({
  hits,
  hasMore,
  refineNext,
}: InfiniteHitsProvided<any>): JSX.Element => (
  <>
    <ArticlesGrid>
      {hits.map(({ objectID, title, slug, heroImage, category }) => (
        <ArticleGridItem key={objectID} uniform>
          <Card
            title={title}
            slug={`/community/${slug}/`}
            subTitle={{
              title: category?.title,
              slug: category?.slug
                ? `/community/category/${category.slug}/`
                : '',
            }}
            image={heroImage}
          />
        </ArticleGridItem>
      ))}
    </ArticlesGrid>
    {hasMore && <LoadMore onClick={refineNext}>Load more articles</LoadMore>}
  </>
)
const CustomHits = connectInfiniteHits(Hits)

const Results = connectStateResults(({ searchResults }) => {
  const communityTextResultFound = useResourceTextData(
    'community.resultFound',
    'result found'
  )
  const communityTextResultsFound = useResourceTextData(
    'community.resultsFound',
    'results found'
  )
  return (
    <SearchResultsMsg>
      <strong>{searchResults?.nbHits}</strong>{' '}
      {searchResults?.nbHits === 1
        ? communityTextResultFound
        : communityTextResultsFound}
    </SearchResultsMsg>
  )
})

type CommunityTypes = {
  data: {}
  location: {}
}

const CommunitySearchResults = ({ location }: PageProps): JSX.Element => {
  const query = location.search.replace('?query=', '')
  return (
    <Layout>
      <Seo title={`Search results for "${query}"`} url={location.href} />
      <InstantSearch indexName="Articles" searchClient={algoliaClient}>
        <Configure
          hitsPerPage={HITS_PER_PAGE}
          filters={`node_locale:${getCurrentLang()}`}
          query={query}
        />
        <CommunitySearchBar query={query} />

        <Container>
          <Results />
          <CustomHits />
        </Container>
      </InstantSearch>
    </Layout>
  )
}

export default CommunitySearchResults

export const pageQuery = graphql`
  query CommunitySearchQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
